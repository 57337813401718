.nav-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between; }

.logo-container {
    display: flex;
    flex-direction: row;
    .nav-logo-png {
        width: 120px;
        object-fit: contain;
        margin: 10px 5px;
        height: 50px; } }

.circle {
    height: 50px;
    width: 50px;
    background-color: $logo-green;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 5px 10px 10px;
    font-family: $logo-font;
    font-size: 20px;
    color: white;
    text-transform: uppercase;
    text-align: center;
    text-shadow: -2px 2px 4px #224d39b3; }

.btn-menu {
    border: none;
    background-color: rgba(0,0,0,0);
    color: $dark-green;
    font-size: 35px;
    margin-right: 10px;
    cursor: pointer; }

.menu-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    height: 100vh;
    width: 0;
    z-index: 100;
    top: 0;
    right: 0;
    overflow: hidden;
    background-color: $logo-green;
    transition: width ease-out 0.3s;
    .btn-menu, .seeker-exit-icon {
        font-size: 30px;
        margin-top: 15px;
        position: absolute;
        right: 10px;
        top: 0; } }

.menu-container-expand {
    width: 100vw; }
.menu-btn-container {
    display: flex;
    flex-direction: column; }

.btn-menu-options {
    width: 200px; }

.btn-danger {
    margin-top: 25px; }

.btn-danger-active {
    background-color: $red; }

.active {
    background-color: #e1f1e4;
    color: $dark-green; }

@media screen and (min-width: 800px) {
    .logo-container {
        min-width: 320px;
        .nav-logo-png {
            width: 200px;
            height: 70px;
            margin: 10px; } }
    .circle {
        height: 70px;
        width: 70px;
        border-radius: 35px;
        font-size: 30px;
        margin: 10px; }
    .btn-menu {
        font-size: 50px;
        margin-right: 5px; }
    .menu-container {
        justify-content: flex-end;
        .menu-btn-container {
            margin-right: 50px; } } }
