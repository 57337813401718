.job-board-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 340px;
    border-radius: 20px;
    background-color: $dark-green;
    min-height: 300px;
    position: relative;
    margin: 15px 5px;
    align-self: baseline;
    .job-container {
        width: 280px;
        margin: 20px 5px; } }

.board-menu {
    align-self: flex-end;
    color: white;
    font-size: 25px;
    margin: 10px 15px;
    border: 0;
    background-color: $dark-green;
    cursor: pointer; }

.board-title {
    color: white;
    text-transform: capitalize;
    font-family: $body-font;
    font-weight: 600;
    margin-top: -10px;
    margin-bottom: 0;
    width: 80%; }

.board-menu-container {
    background-color: $dark-green;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    width: 0;
    overflow: hidden;
    transition:  width ease-out 0.3s;
    .seeker-exit-icon {
        color: $logo-green;
        font-size: 25px; }
    .btn-menu {
        align-self: flex-end;
        margin: 10px;
        margin-top: 15px; }
    .input-basic {
        width: 220px; }
    form {
        display: flex;
        flex-direction: column;
        align-items: center; } }
.btn-board {
    background-color: $logo-green; }

.btn-danger-active {
    background-color: $red; }

.board-menu-container-expand {
    width: 100%; }

@media screen and (min-width: 500px) {
    .job-board-container {
        width: 400px;
        .job-container {
            width: 320px; } } }
