body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: $body_font;
    background-image: linear-gradient(to right, #d5f2da, #b2dabd, #8fc3a1, #6aac87, #42966f); }

a {
    text-decoration: none;
    p {
        &:hover {
            color: $dark-green; } } }

.drop-shadow {
    -webkit-box-shadow: 8px 8px 16px -4px rgba(34, 77, 57, 0.7);
    -moz-box-shadow: 8px 8px 16px -4px rgba(34, 77, 57, 0.7);
    box-shadow: 8px 8px 16px -4px rgba(34, 77, 57, 0.7); }


.btn-basic {
    font-family: $title-font;
    text-transform: capitalize;
    padding: 0 20px 0 20px;
    color: white;
    background-color: $dark-green;
    border: none;
    margin: 10px;
    font-size: 16px;
    height: 40px;
    border-radius: 15px;
    cursor: pointer;
    transition: transform 0.3s ease-out, filter 0.3s ease-out;

    &:hover {
        transform: scale(1.1);
        filter: brightness(110%); } }

.btn-basic-active {
    background-color: white;
    color: $dark-green; }

.input-basic {
    height: 40px;
    border-radius: 15px;
    border: none;
    color: $grey-green;
    width: 280px;
    padding: 0 20px 0 20px;
    margin: 10px; }

.error-message, .success-message {
    text-align: center; }

.error-message {
    color: $red; }

.success-message {
    color: $black-green; }

