.login-container {
    min-height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center; }

.btn-login-toggle {
    position: absolute;
    top: 15px;
    right: 15px; }
.form-container {
    display: flex;
    flex-direction: column;
    align-items: center; }
.login-logo {
    width: 80vw;
    margin-top: 80px; }

.login-form, .signup-form {
    display: flex;
    flex-direction: column;
    align-items: center; }

@media screen and (min-width: 800px) {
    .login-logo {
        width: 50vw; }
    .login-form {
        flex-direction: row; } }
