.job-container {
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 310px;
    border-radius: 20px;
    padding: 20px;
    margin: 15px 5px;
    align-self: baseline;
    .pin-job {
        align-self: flex-end;
        padding: 0; }
    .seeker-pin-tilt-icon, .seeker-delete-icon {
        color: $grey-green;
        font-size: 25px;
        &:hover {
            color: $dark-green; } }
    .seeker-pin-icon {
        color: $black-green;
        font-size: 25px;
        align-self: flex-end;
        padding: 0; }
    h2 {
        font-family: $body-font;
        font-weight: 700;
        text-transform: capitalize;
        margin: 0;
        margin-bottom: 5px;
        color: $black-green; } }

.primary-info {
    color: $black-green;
    margin: -5px 0;
    font-size: 18px;
    font-weight: 400; }

.secondary-info p, .tertiary-info p {
    color: $grey-green;
    width: fit-content; }

.secondary-info span, .tertiary-info span {
    margin-right: 10px; }

.secondary-info p:first-of-type span, .tertiary-info p:first-of-type span {
    margin-right: 5px; }

.secondary-info, .tertiary-info {
    display: flex;
    justify-content: space-between;
    p {
        font-size: 14px; } }
.secondary-info {
    p {
        margin-top: 5px; } }

.job-description {
    font-weight: 300;
    overflow: hidden;
    margin-bottom: 40px;
    height: auto;
    max-height: 600px;
    transition: max-height 0.3s linear; }

.hide-job-description {
    color: white;
    max-height: 0;
    margin-bottom: 0; }


.card-btn {
    border: none;
    background-color: rgba(0,0,0,0);
    width: max-content;
    cursor: pointer;
    .seeker-chevron-down-icon {
        font-size: 50px;
        transition: transform 0.3s linear;
        cursor: pointer; }
    .rotate {
        transform: rotate(180deg); } }

.card-btn-expand {
    margin: -20px auto; }

.delete-job-background, .pin-job-background {
    position: fixed;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.6);
    z-index: 30; }

.delete-job-expand, .pin-job-expand {
    height: 100vh;
    width: 100vw; }

.delete-job-container, .pin-job-container {
    width: 300px;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $dark-green;
    border-radius: 20px;
    .btn-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px; }
    .seeker-exit-icon {
        color: $logo-green;
        font-size: 25px; }
    .pin-board-title {
        margin-bottom: 15px; }
    .btn-menu {
        align-self: flex-end;
        margin: 10px;
        margin-top: 15px;
        background: none;
        border: none; }
    .btn-pin-menu {
        min-height: 40px;
        font-size: 16px;
        height: fit-content; }
    .btn-basic {
        background-color: $logo-green;
        width: 200px; }
    .btn-danger-active {
        background-color: $red; } }

@media screen and (min-width: 500px) {
    .pin-job-container {
        width: 400px;
        .btn-pin-menu {
            width: 300px; } } }

@media screen and (min-width: 800px) {
    .job-container {
        margin: 15px; } }



