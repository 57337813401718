$logo-green: rgb(83, 171, 128);
$dark-green: rgb(56, 128, 96);
$black-green: rgb(15, 56, 43);
$grey-green: rgb(143, 171, 159);
$red: rgb(201, 48, 48);
//fonts
$logo-font: 'Fredoka One', cursive;

$body-font: 'Open Sans', sans-serif;

$title-font: 'Poppins', sans-serif;

// icon font
$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$seeker-list-icon: "\e90e";
$seeker-location-icon: "\e900";
$seeker-pin-tilt-icon: "\e901";
$seeker-search-icon: "\e902";
$seeker-spinner-icon: "\e903";
$seeker-briefcase-icon: "\e904";
$seeker-chevron-down-icon: "\e905";
$seeker-chevron-up-icon: "\e906";
$seeker-delete-icon: "\e907";
$seeker-dropdown-icon: "\e908";
$seeker-elipsis-icon: "\e909";
$seeker-exit-icon: "\e90a";
$seeker-logo-icon: "\e90b";
$seeker-menu-icon: "\e90c";
$seeker-pin-icon: "\e90d";

@font-face {
    font-family: 'icomoon';
    src:  url('../fonts/icomoon.eot?1cvdwe');
    src:  url('../fonts/icomoon.eot?1cvdwe#iefix') format('embedded-opentype'), url('../fonts/icomoon.ttf?1cvdwe') format('truetype'), url('../fonts/icomoon.woff?1cvdwe') format('woff'), url('../fonts/icomoon.svg?1cvdwe#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block; }


[class^="seeker-"], [class*=" seeker-"] {
    font-family: $icomoon-font-family !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.seeker-list-icon {
    &:before {
        content: $seeker-list-icon; } }

.seeker-location-icon {
    &:before {
        content: $seeker-location-icon; } }

.seeker-pin-tilt-icon {
    &:before {
        content: $seeker-pin-tilt-icon; } }


.seeker-search-icon {
    &:before {
        content: $seeker-search-icon; } }


.seeker-spinner-icon {
    &:before {
        content: $seeker-spinner-icon; } }

.seeker-briefcase-icon {
    &:before {
        content: $seeker-briefcase-icon; } }

.seeker-chevron-down-icon {
    &:before {
        content: $seeker-chevron-down-icon; } }

.seeker-chevron-up-icon {
    &:before {
        content: $seeker-chevron-up-icon; } }

.seeker-delete-icon {
    &:before {
        content: $seeker-delete-icon; } }

.seeker-dropdown-icon {
    &:before {
        content: $seeker-dropdown-icon; } }

.seeker-elipsis-icon {
    &:before {
        content: $seeker-elipsis-icon; } }

.seeker-exit-icon {
    &:before {
        content: $seeker-exit-icon; } }


.seeker-logo-icon {
    &:before {
        content: $seeker-logo-icon; } }

.seeker-menu-icon {
    &:before {
        content: $seeker-menu-icon; } }

.seeker-pin-icon {
    &:before {
        content: $seeker-pin-icon; } }
