.home-container {
    min-height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center; }

.greeting {
    font-size: 45px;
    font-family: $logo-font;
    text-shadow: -2px 2px 4px #224d39b3;
    color: white;
    margin: 20px 0;
    align-self: flex-start;
    margin-left: 10px; }

.search-form-main {
    display: flex;
    flex-direction: column;
    align-items: center; }


@media screen and (min-width: 800px) {
    .greeting {
        font-size: 80px; }
    .search-form-main {
        flex-direction: row; } }

